.user-window {
	width: 100%;
	aspect-ratio: 16 / 10;
	overflow: hidden;
    position: relative;

	background-color: #6d6d6d;//#000000;//#6d6d6d;
	border-radius: 4px;
}
.user-window--no-ratio {
	aspect-ratio: unset;
}