.event-item {
	position: relative;

	border: 1px solid #e2e2e2;
	border-radius: 8px;
	cursor: pointer;

	transition: all 0.4s;
}
.event-item:hover {
	background-color: rgba(0, 0, 0, 0.04);

	transition: all 0.4s;
}
.event-item__date {
	min-width: 120px;
}
.event-item__icon {
	position: relative;

	display: block;
	width: 80px;
	height: 80px;

	border-radius: 50%;
	border: 3px solid #607d8b;

	@media(min-width: 900px) {
		margin-top: -24px;
		margin-bottom: -24px;
	}
}