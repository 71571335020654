.game-item {
	width: 100%;
	min-height: 280px;
	display: flex;
	gap: 6px;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;

	text-align: center;
	color: #ffffff;

	cursor: pointer;
	background-size: cover;

	transition: all 0.4s;
}
.game-item:hover {
	box-shadow: 0px 10px 10px 2px rgba(34, 60, 80, 0.2);

	transition: all 0.4s;
}