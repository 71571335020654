.chat {
	width: 100%;

	border: 1px solid rgb(242, 244, 247);
	overflow: hidden;
}
.chat__body {
	height: 260px;

	overflow: auto;

	@media(min-width: 992px) {
		height: 400px;
	}
}