.active-game-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;

	border: 1px solid #e2e2e2;
	border-radius: 8px;
	cursor: pointer;

	transition: all 0.4s;
	position: relative
}
.active-game-item:hover {
	background-color: rgba(0, 0, 0, 0.04);

	transition: all 0.4s;
}