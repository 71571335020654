.chat-message__body {
	padding: 8px 16px;

	box-shadow: 2px 2px 8px 0px rgba(34, 60, 80, 0.12);
	background-image: none;
	overflow: hidden;
	border-radius: 20px;
	background-color: rgb(255, 255, 255);
	color: rgb(17, 25, 39);	

	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	@media(min-width: 992px) {
		max-width: 60%;
	}
}
.chat-message__reverse .chat-message__body {
	color: #ffffff;
	
	background-color: #42a5f5;
}