body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antihackworded;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mobile-hidden {
  @media(max-width: 899px) {
    display: none !important;
  }
}
.mobile-show-flex {
  display: none !important;
  @media(max-width: 899px) {
    display: flex !important;
  }
}

.mobile-show-block {
  display: none !important;
  @media(max-width: 899px) {
    display: block !important;
  }
}

.navbar__nav-link.active {
  background: rgba(255, 255, 255, 0.15);
}

.words-list-transition--first {
  animation: fade-in 500ms forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.home-page {
  min-height: 100vh;

  background-image: url("/assets/images/home-bg.png");
  background-size: cover;
  background-position: center;
}
.home-page__body {
  color: #ffffff;
}
.home-page .MuiTypography-h1 {
  @media(max-width: 1199px) {
    font-size: 60px;
    line-height: 72px;
  }
}
.article img,
.article img + em {
  display: block;
  width: 80%;
  margin: 0 auto;

  text-align: center;
  font-size: 90%;
}

.pretty-scrollbar {
  scrollbar-color: red orange !important;
  scrollbar-width: thin !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: #e3e3e3; /* or add it to the track */
}
::-webkit-scrollbar-thumb {
  background: #8a8a8a;
  border-radius: 10px;
}

.admin-label-style {
  position: absolute;
  left: 16%;
  top: 8%;
  background-color:rgb(41, 41, 41);
  z-index: 101;
}

.markdown > * {
  all: revert;
  text-align: justify;
}