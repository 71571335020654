.user-item {
	border: 1px solid #e2e2e2;
	border-radius: 8px;
	cursor: pointer;

	transition: all 0.4s;
}
.user-item:hover {
	background-color: rgba(0, 0, 0, 0.04);

	transition: all 0.4s;
}

.me-user-item {
	border: 2px solid #7276f7;
	border-radius: 8px;
	cursor: pointer;

	transition: all 0.4s;
}