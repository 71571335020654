.room {
	padding: 8px;

	border: 1px solid #e2e2e2;
	border-radius: 8px;
	cursor: pointer;

	transition: all 0.4s;
}
.room:hover {
	background-color: rgba(0, 0, 0, 0.04);

	transition: all 0.4s;
}
.room__tags {
	display: flex;
	flex-wrap: wrap;	
	padding: 0;
	margin: 0;
	padding-top: 6px;
	gap: 4px;
	max-height: 52px;
	
	overflow: hidden;
	list-style: none;
}