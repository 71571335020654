.all-words {
	
}
.all-words__count {
	color: #2e7d32;
}
.all-words__body {
	max-height: 400px;
	overflow: auto;
}
