.features-item {
	color: #ffffff;
	text-decoration: none;
	
	cursor: pointer;

	transition: all 0.4s;
}
.features-item:hover {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 4px;

	transition: all 0.4s;
}
.features-item img {
	display: block;
	width: 64px;
	height: 64px;
}