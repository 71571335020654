.community-item {
	cursor: pointer;

	transition: all 0.3s;
}
.community-item:hover {
	background-color: #a1a1a1;
	border-radius: 10px;

	transition: all 0.3s;
}