.navbar {

}
.navbar__nav-link {
	position: relative;

	padding: 6px 8px;	
	margin: 16px 0px;
	display: block;

	color: white;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	text-decoration: none;

	border-radius: 4px;
	background-color: transparent;

	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.navbar.navbar--home {
	background-color: rgba(0, 0, 0, 0.15);
	background-image: none;
	box-shadow: none;
}