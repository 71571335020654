.community-header {}
.community-header__img {
	display: block;
	width: 100%;
	max-width: 180px;

	border-radius: 50%;

	@media(min-width: 992px) {
		max-width: 280px;
	}
}